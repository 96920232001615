<template>
  <div>
    <Notification
      v-if="logoutReason"
      type="warning"
    >
      {{ logoutReason }}
    </Notification>

    <div class="columns has-background-white">
      <div class="column">
        <h1 class="title is-size-5">Login</h1>
        <form @submit.prevent="onSubmit">
          <BaseInput
            label="Email"
            type="email"
            v-model.trim="$v.user.email.$model"
            :validation="$v.user.email"
          />
          <BaseInput
            label="Password"
            type="password"
            v-model="$v.user.password.$model"
            :validation="$v.user.password"
          />
          <a
            @click="forgot"
            class="is-pulled-right"
          >
          forgot password
          </a>
          <BaseSubmit
            label="Login"
            :error="error"
            :disabled="$v.$invalid"
            :submitting="submitting"
          />
        </form>
      <p class="subtitle is-size-6" style="margin-top:10px;">
          Don't have an account? <a @click="signup">Sign up</a>
        </p>
      </div>
      <div class="column has-background-info has-text-white" style="padding:40px;">
        <h1 class="title has-text-white"><Brand /></h1>
        <p class="subtitle has-text-white">Discover the best local deals</p>
      </div>
    </div>
  </div>
</template>

<script>
import {AuthError, NetworkError} from "@/errors/index.js";
import { email, minLength, required } from 'vuelidate/lib/validators';
import Brand from "@/components/Brand.vue";
import Notification from "@/components/Notification.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";

export default {
  components: {
    Brand,
    Notification,
    BaseInput,
    BaseSubmit
  },
  data() {
    return {
      submitting: false,
      error: null,
      user: {
        email: '',
        password: ''
      }
    }
  },
  validations: {
     user: {
      email: { required, email},
      password: { required, minLength: minLength(8) }
    }
  },
  computed: {
    logoutReason() {
      return (this.$route.query.r == "security") ? "You have been logged out for security reasons. Please log in again." : "";
    }
  },
  methods: {
    go(path) {
      this.$router.push(path);
    },
    forgot() {
     this.go("/password-forgot")
    },
    signup() {
      const path = (window.NativeScript) ? "/signup?t=member" : "/get-started"
      this.go(path)
    },
    onSubmit() {
      this.submitting = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let params = {
        email: this.user.email,
        password: this.user.password
      }
      this.$store.dispatch("login", params)
        .then(() => {
          this.submitting = false
          this.$router.push("/");
        })
        .catch(error => {
          this.submitting = false
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = error
          }
        })
    }
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      if (window.NativeScript) {
        window.NativeScript.setLoginState(true);
      }
      this.$router.push("/")
    } else if (window.NativeScript) {
        window.NativeScript.setLoginState(false);
    }
  },
}
</script>
